'use client';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useRouter } from 'next/navigation';

const SectionFive = () => {
    const router = useRouter();
    const [citiesData, setCitiesData] = useState([]);

    const fetchingCitiesApi = async () => {
        try {
            const response = await axios({
                method: "GET",
                url: `${process.env.NEXT_PUBLIC_REACT_APP_HOST_URL}/api/get/data/cities/by/status`,
            });
            console.log("here is rsponse", response);
            if (response.status === 200) {
                setCitiesData(response.data.data.slice(0, 4));
            }
        } catch (error) {
            console.log('Failed while fetching cities data api: ', error);
        }
    };

    useEffect(() => {
        fetchingCitiesApi();
    }, []);

    const handleNavigate = (cityName, cityId) => {
        router.push({
            pathname: '/cars',
            query: { city: cityName.toLowerCase() },
        }, undefined, { shallow: true });
    };

    return (
        <div className='home-section-five'>
            <div className="section-five-header">
                <h3 className='primary_text_color'>Find car rental services near you</h3>
            </div>

            <div className="cities-cards-container">
                {citiesData.length > 0 ? citiesData.map((item, index) => (
                    <div
                        onClick={() => handleNavigate(item.cityName, item.id)}
                        key={index}
                        className="city-card"
                    >
                        <div className='city-card-img' style={{ backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0) 20%, rgba(0,0,0,1)), url(${process.env.NEXT_PUBLIC_REACT_APP_HOST_URL}/images/cities-images/${item.pictureUrl})` }}>
                            <p className='city-name'>{item.cityName}</p>
                            <p className='cars-details'>View Rental Cars in {item.cityName}</p>
                        </div>
                    </div>
                )) : ''}
            </div>
        </div>
    );
};

export default SectionFive;
