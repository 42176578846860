'use client';
import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import axios from "axios";
import { useRouter } from "next/navigation";
import Image from 'next/image';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const BrandCardSlider = () => {
    const router = useRouter();

    const [brandsData, setBrandsData] = useState([]);
    const [lengthData, setLengthData] = useState([]);

    const fetchingBrandsApi = async () => {
        try {
            let response = await axios({
                method: 'GET',
                url: `${process.env.NEXT_PUBLIC_REACT_APP_HOST_URL  }/api/cars-brand`
            });
            if (response.status === 200) {
                setBrandsData(response.data);
            }
        } catch (error) {
            console.error('Failed while fetching brands api: ', error);
        }
    };

    const fetchingCarsCountApiByBrand = async () => {
        try {
            let response = await axios({
                method: "GET",
                url: `${process.env.NEXT_PUBLIC_REACT_APP_HOST_URL}/api/cars-brand`
            });
            if (response.status === 200) {
                setLengthData(response.data);
            }
        } catch (error) {
            console.error('Failed while fetching car brands length api: ', error);
        }
    };

    function NextCard(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ display: "flex", background: "whitesmoke", borderRadius: '50%', padding: 20, justifyContent: 'center', alignItems: 'center' }}
                onClick={onClick}
            />
        );
    }

    function PreviousCard(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ display: "flex", background: "whitesmoke", borderRadius: '50%', padding: 20, justifyContent: 'center', alignItems: 'center' }}
                onClick={onClick}
            />
        );
    }

    let sliderRef = useRef(null);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 9,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            { breakpoint: 1024, settings: { slidesToShow: 7 } },
            { breakpoint: 768, settings: { slidesToShow: 6 } },
            { breakpoint: 700, settings: { slidesToShow: 5 } },
            { breakpoint: 500, settings: { slidesToShow: 4 } },
            { breakpoint: 400, settings: { slidesToShow: 3 } },
            { breakpoint: 300, settings: { slidesToShow: 2 } },
            { breakpoint: 250, settings: { slidesToShow: 1 } },
        ]
    };

    useEffect(() => {
        fetchingBrandsApi();
        fetchingCarsCountApiByBrand();
    }, []);

    return (
        <>
            {brandsData.length > 8 ? (
                <Slider {...settings}>
                    {brandsData.map((item, index) => (
                         <div 
                            key={index} 
                            onClick={() => { 
                                router.push(`/cars?brand=${item.brandName.toLowerCase()}`); 
                            }}
                        >
                            <div className="brand-card">
                              <div className="logo-wrapper">
                                <Image 
                                        src={`${item.brandLogo}`} 
                                        alt={item.brandLogo} 
                                        className='brand-img ' 
                                        width={70} 
                                        height={70} 
                                    />
                              </div>
                                <span style={{fontSize:'13px'}} className="brands-cards-slider-text">{item.brandName}</span>
                                    <h6 className='mb-0' style={{fontSize:'12px'}}>
                                        {item.totalCars} cars
                                    </h6>
                                    
                            </div>
                        </div>
                    ))}
                </Slider>
            ) : (
                <div className="list-container">
                    {brandsData.map((item, index) => (
                        <div 
                            key={index} 
                            onClick={() => { 
                                router.push({ 
                                    pathname: '/cars', 
                                    query: { brand: item.brandName.toLowerCase()} 
                                }); 
                            }}
                        >
                            <div className="brand-card">
                                <Image 
                                    src={`${item.brandLogo}`} 
                                    className='brand-img' 
                                    alt={item.brandLogo} 
                                    width={100} 
                                    height={100} 
                                />
                                <span className="brands-cards-slider-text">{item.brandName}</span>
                                <h6 className='mb-0'>
                                    {lengthData.map((itm, ind) => (
                                        <span style={{ fontSize: 12 }} key={ind}>
                                            {item.id === itm.carBrandId ? `${itm.total_length} Cars` : ''}
                                        </span>
                                    ))}
                                </h6>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

export default BrandCardSlider;
