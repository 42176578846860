'use client';
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/navigation';
import axios from 'axios';
import '../../../src/styles/home.css';
import Image from 'next/image';

import downIcon from "../../assets/icons/down.png";
import upIcon from "../../assets/icons/up.png";

const SectionTwo = () => {
    const router = useRouter();

    const [carTypesData, setCarTypesData] = useState([]);
    const [carTypeDataLimit, setCarTypeDataLimit] = useState(10);

    const vehicleTypeOrder = [
        'Sedan', 'Hatchback','Electric', 'Convertible', 'Sports', 'Coupe',
        'Crossover', 'SUV', 'Luxury Suv', 'Jeep & Truck', 'Bus', 
        'Yacht', 'Tour Package', 'Driver Service'
    ];

    const fetchingCarTypesData = async () => {
        try {
            const response = await axios.get(`${process.env.NEXT_PUBLIC_REACT_APP_HOST_URL}/api/vehicle-type`);
            if (response.status === 200) {
                const fetchedData = response.data.carTypes;
                const sortedData = vehicleTypeOrder
                    .map(type => fetchedData.find(item => item.name.toLowerCase() === type.toLowerCase()))
                    .filter(Boolean); 
                setCarTypesData(sortedData);
            }
        } catch (error) {
            console.log('Failed while fetching car types data: ', error);
        }
    };

    useEffect(() => {
        fetchingCarTypesData();
    }, []);

    const handleNavigate = (path, query) => {
        const queryString = query ? `?${new URLSearchParams(query).toString()}` : '';
        router.push(`${path}${queryString}`);
    };

    return (
        <>
            <div className='home-section-two'>
                <div className="section-two-header">
                    <h3 className='section-two-main-heading'>
                        Book Your Car Rental, Luxury Yacht, and Tour Packages 
                    </h3>
                </div>
                <div className="cards-container">
                    {
                        carTypesData.length > 0 &&
                            carTypesData.slice(0, carTypeDataLimit).map((item, index) => (
                                <React.Fragment key={index}>
                                    <div
                                        onClick={() => {
                                        //    if (item.category.toLowerCase() === 'yacht') {
                                        //         handleNavigate('/yachts');
                                        //     } else if (item.category.toLowerCase() === 'tour packages') {
                                        //         handleNavigate('/desert-safari');
                                        //     } else if (item.category.toLowerCase() === 'driver services') {
                                        //         handleNavigate('/driver-service');
                                        //     } else  {
                                                router.push(`/cars?type=${item.name}`);
                                            // } 
                                        }} 
                                        className="cards"
                                    >
                                        <div className="car-card">
                                            <Image 
                                                src={item.image} 
                                                alt={item.name} 
                                                className='car-card-img' 
                                                width={200} 
                                                height={150} 
                                            />
                                            <span className="text-center home-section-two-text">
                                                {item.name}
                                            </span>
                                        </div>
                                    </div>
                                </React.Fragment>
                            ))
                    }
                </div>

                {
                    carTypeDataLimit === carTypesData.length ?
                        <div className='mt-4 d-flex justify-content-center'>
                            <button onClick={() => { setCarTypeDataLimit(10) }} className="btn primary_text_color section-two-btn">
                                See less <Image className='ms-1' src={upIcon} alt="up-icon" height={15} width={15} />
                            </button>
                        </div>
                        :
                        <div className='mt-4 d-flex justify-content-center'>
                            <button onClick={() => { setCarTypeDataLimit(carTypesData.length) }} className="btn primary_text_color section-two-btn">
                                See more <Image src={downIcon} alt="down-icon" height={25} width={25} />
                            </button>
                        </div>
                }

                <div className='text-container'>
                    <p className='para'>
                        Looking for a reliable budget-friendly rent a car near you? Your search ends here. With DubyDrive, simply enjoy easy and direct booking with our top-notch car rental partner companies in UAE and experience outstanding services at an affordable price. Get great deals when you book a rental car through us. DubyDrive.com is the most advanced car rental online portal and business platform in Dubai, dedicated to simplifying the process of renting all types of vehicles. Choose your dream vehicles from our most trusted car rental businesses. Whether you are a tourist or a local resident, we assure you will get the best and cheapest rental deal in the town.
                    </p>
                </div>
            </div>
        </>
    );
};

export default SectionTwo;
