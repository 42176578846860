'use client';
import React, { useEffect, useState } from "react";
import { useRouter } from 'next/navigation';
import axios from "axios";
import Image from 'next/image';
import Link from "next/link";
import Slider from "react-slick";
import '../../../src/styles/home.css'
import '../../../src/styles/cars.css'
import '../../../src/styles/index.css'
import '../../../src/styles/banner.css';

import HomePageBannerOne from '../../components/banners/home-page-banner-one';
import HomePageBannerTwo from '../../components/banners/home-page-banner-two';
import HomePageBannerThree from '../../components/banners/home-page-banner-three';
import "slick-carousel/slick/slick.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick-theme.css";

import nextIcon from "../../assets/icons/next.png";
import userIcon from "../../assets/icons/user.png";
import typeIcon from "../../assets/icons/type.png";
import doorIcon from "../../assets/icons/door.png";
import seatIcon from "../../assets/icons/seat.png";
import fuelIcon from "../../assets/icons/fuel.png";
import infoIcon from "../../assets/icons/info.png";
import checkIcon from "../../assets/icons/check.png";
import callIcon from "../../assets/icons/call.png";
import whatsappIcon from "../../../app/assets/icons/whatsapp.png";
import sendIcon from "../../assets/icons/send.png";
import transmissionIcon from "../../assets/icons/transmission.png";
import roadIcon from "../../assets/icons/road.png";

import CarCardSlider from '../../components/carousels/car_cards';

const SectionFour = () => {

    const router = useRouter();
    const [carsData, setCarData] = useState([]);
    
  const [firstSliderCars, setFirstSliderCars] = useState([]);
  const [secondSliderCars, setSecondSliderCars] = useState([]);
  const [thirdSliderCars, setThirdSliderCars] = useState([]);

  const firstSliderIds = [84, 85, 29, 46, 177, 89, 202, 193, 182, 187];
  const secondSliderIds = [82, 30, 38, 53, 137, 179, 197, 195, 159, 183];
  const thirdSliderIds = [87, 36, 68, 128, 176, 173, 110, 115, 192, 191];

    const navigateToCars = () => {
        router.push('/cars');
    };

    const fetchingCarDataApi = async () => {
        try {
          const response = await axios.get(
            `${process.env.NEXT_PUBLIC_REACT_APP_HOST_URL}/api/cars`
          );
          if (response.status === 200) {
            const fetchedCarData = response.data.result;
            setCarData(fetchedCarData); // Store all car data
    
            // Filter car data based on the specific IDs for each slider
            const firstSliderFiltered = fetchedCarData.filter((car) =>
              firstSliderIds.includes(car.id)
            );
            const secondSliderFiltered = fetchedCarData.filter((car) =>
              secondSliderIds.includes(car.id)
            );
            const thirdSliderFiltered = fetchedCarData.filter((car) =>
              thirdSliderIds.includes(car.id)
            );
    
            // Set the filtered data for each slider
            setFirstSliderCars(firstSliderFiltered);
            setSecondSliderCars(secondSliderFiltered);
            setThirdSliderCars(thirdSliderFiltered);
          }
        } catch (error) {
          console.error("Failed to fetch car data:", error);
        }
      };
    
      useEffect(() => {
        fetchingCarDataApi();
      }, []);

      const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
          { breakpoint: 1550, settings: { slidesToShow: 4 } },
          { breakpoint: 1300, settings: { slidesToShow: 3 } },
          { breakpoint: 1050, settings: { slidesToShow: 2 } },
          { breakpoint: 768, settings: { slidesToShow: 1.1 } },
        ],
      };

    return (
        <>
            <div className='home-section-four'>
                <div className="section-four-header">
                    <h3 className='primary_text_color'>Find all exclusive car rental services near you</h3>
                    <button onClick={navigateToCars} className="btn primary_background_color text-white">
                        View all <Image src={nextIcon} className='ms-1' alt="next-icon" height={15} width={15} />
                    </button>
                </div>
                <p className="para mb-0">
                    Compare price with all other car rental businesses near you to find the best rental deals available.
                </p>
                <div className="car-cards-container mt-0">
                    <Slider {...settings}>
                        {firstSliderCars.map((item) => (
                            <div key={item.id} className="car-cards car-cards-slider">
                            <Link href={{ pathname: "/car-details", query: { id: item.id } }}
                                >
                                <div className="card-header">
                                    {item.carImages && item.carImages.length > 0 ? (
                                    <Image
                                        src={item.featureImage}
                                        alt={item.carImages[0]}
                                        onError={(e) => {
                                        e.target.src = defaultCarImage.src;
                                        }}
                                        className="card-header-img"
                                        width={400}
                                        height={200}
                                    />
                                    ) : (
                                    <Image
                                        src={defaultCarImage.src}
                                        alt="default-car"
                                        className="card-header-img"
                                        width={400}
                                        height={200}
                                    />
                                    )}
                                    {carsData !== null ? (
                                    <div className="car-card-badge-container">
                                        {item.dealer.package.premium == true ? (
                                        <span className="badge text-bg-dark car-card-badge">
                                            Premium
                                        </span>
                                        ) : (
                                        ""
                                        )}
                                        {item.dealer.package.featured == true ? (
                                        <span className="badge text-bg-warning car-card-badge">
                                            Featured
                                        </span>
                                        ) : (
                                        ""
                                        )}
                                        {item.dealer.package.verify == true ? (
                                        <span className="badge text-bg-success car-card-badge">
                                            Verified
                                        </span>
                                        ) : (
                                        ""
                                        )}
                                        {item.discountOfferPercentage == "0" ? (
                                        ""
                                        ) : (
                                        <span className="badge text-bg-light car-card-badge">
                                            {item.discountOfferPercentage}% Discount
                                        </span>
                                        )}
                                    </div>
                                    ) : (
                                    ""
                                    )}
                                </div>
                                <div className="card-body p-3">
                                    <h4>{item.modelNo}</h4>
                                    <div className="card-details">
                                    <div className="details-one">
                                        {item.discountOfferPercentage == "0" ? (
                                        <span className="details text-orange">
                                            <span className="text-line">
                                            {" "}
                                            <br />{" "}
                                            </span>
                                        </span>
                                        ) : (
                                        <span className="details text-orange">
                                            <span className="text-line">
                                            {item.dayBasisCost}
                                            </span>
                                        </span>
                                        )}
                                        <span className="details text-orange">
                                        AED{" "}
                                        {Number(item.dayBasisCost) -
                                            (Number(item.dayBasisCost) / 100) *
                                            Number(item.discountOfferPercentage)}{" "}
                                        <span className="primary_text_color">/ day</span>
                                        </span>
                                        <div className="d-flex align-items-center">
                                        <Image
                                            src={roadIcon}
                                            alt="road-icon"
                                            className="spec-icon"
                                            width={400}
                                            height={200}
                                        />
                                        <span className="details text-gray">
                                            {item.dayBasisMileageLimit}
                                        </span>
                                        </div>
                                    </div>
                                    <div className="details-two">
                                        {item.discountOfferPercentage == "0" ? (
                                        <span className="details text-orange">
                                            <span className="text-line">
                                            {" "}
                                            <br />{" "}
                                            </span>
                                        </span>
                                        ) : (
                                        <span className="details text-orange">
                                            <span className="text-line">{item.weeklyCost}</span>
                                        </span>
                                        )}
                                        <span className="details text-orange">
                                        AED{" "}
                                        {Number(item.weeklyCost) -
                                            (Number(item.weeklyCost) / 100) *
                                            Number(item.discountOfferPercentage)}{" "}
                                        <span className="primary_text_color">/ week</span>
                                        </span>
                                        <div className="d-flex align-items-center">
                                        <Image
                                            src={roadIcon}
                                            alt="road-icon"
                                            className="spec-icon"
                                            width={400}
                                            height={200}
                                        />
                                        <span className="details text-gray">
                                            {item.weeklyMileageLimit}
                                        </span>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="specs">
                                    <div className="medium-spec w-auto px-3">
                                        <Image
                                        src={typeIcon}
                                        alt="type-icon"
                                        className="spec-icon"
                                        width={400}
                                        height={200}
                                        />
                                        <span className="text-gray">{item.type.name}</span>
                                    </div>
                                    <div className="small-spec">
                                        <Image
                                        src={doorIcon}
                                        alt="door-icon"
                                        className="spec-icon"
                                        width={400}
                                        height={200}
                                        />
                                        <span className="text-gray">{item.doors}</span>
                                    </div>
                                    <div className="small-spec">
                                        <Image
                                        src={seatIcon}
                                        alt="seat-icon"
                                        className="spec-icon"
                                        width={400}
                                        height={200}
                                        />
                                        <span className="text-gray">{item.seats}</span>
                                    </div>
                                    <div className="medium-spec">
                                        <Image
                                        src={fuelIcon}
                                        alt="fuel-icon"
                                        className="spec-icon"
                                        width={400}
                                        height={200}
                                        />
                                        <span className="text-gray">{item.fuelType.name}</span>
                                    </div>
                                    <div className="medium-spec">
                                        <Image
                                        src={transmissionIcon}
                                        alt="transmission-icon"
                                        className="spec-icon"
                                        width={400}
                                        height={200}
                                        />
                                        <span className="text-gray">
                                        {item.autoTransmission == "Yes" ? "Auto" : "Manual"}
                                        </span>
                                    </div>
                                    </div>
                                </div>
                                </Link>
                                <div className=" d-flex justify-content-between p-2">
                                <div className="card-footer-dealer-logo-container ">
                                    <Image
                                    src={`${item.dealer.logo}`}
                                    alt="dealer-logo"
                                    className="dealer-logo"
                                    width={100}
                                    height={200}
                                    style={{ objectFit: "contain" }}
                                    />
                                </div>
                                <div className="card-footer-details">
                                    <div className="footer-info">
                                    <Image
                                        src={checkIcon}
                                        alt="check-icon"
                                        className="footer-icons check-icon"
                                        width={400}
                                        height={200}
                                    />
                                    <span className="card-footer-text text-gray">
                                        {item.rentalDays} day rental available
                                    </span>
                                    </div>
                                    <div className="footer-info">
                                    <Image
                                        src={infoIcon}
                                        alt="inof-icon"
                                        className="footer-icons"
                                        width={400}
                                        height={200}
                                    />
                                    <span className="card-footer-text text-gray">
                                        Deposit: {item.securityDeposit}
                                    </span>
                                    </div>
                                    <div className="footer-info">
                                    <Image
                                        src={checkIcon}
                                        alt="check-icon"
                                        className="footer-icons check-icon"
                                        width={400}
                                        height={200}
                                    />
                                    <span className="card-footer-text text-gray">
                                        Insurance Included
                                    </span>
                                    </div>
                                </div>
                                </div>
                                <div className="card-buttons">
                                <a
                                    href={`tel:${item.dealer.contactNumber}`}
                                    data-tooltip-id="vender_contact"
                                    data-tooltip-content={"Phone"}
                                    className="card-btn primary_background_color phone-btn"
                                >
                                    <Image
                                    src={callIcon}
                                    alt="call-icon"
                                    className="card-btn-icons"
                                    width={400}
                                    height={200}
                                    />
                                </a>
                                <a
                                    data-tooltip-id="vender_email"
                                    data-tooltip-content={"Whatsapp"}
                                    className="card-btn background-green"
                                    onClick={() => {
                                    const phone = item.dealer.whatsappNumber;
                                    const message = `Hi, I've found your (${
                                        item.modelNo
                                    }) at dubydrive.com and I'd like to rent the listed car. ${window.location.toString()} Let me know if it's still available. Thanks!`;

                                    const url = `https://api.whatsapp.com/send?phone=${phone}&text=${encodeURIComponent(
                                        message
                                    )}&type=phone_number&app_absent=0`;

                                    window.open(url, "_blank");
                                    }}
                                >
                                    <Image
                                    src={whatsappIcon}
                                    alt="whatsapp-icon"
                                    className="card-btn-icons"
                                    width={400}
                                    height={200}
                                    />
                                </a>
                                <a
                                    href={`mailto:${item.dealer.user.email}?body=Hello can i get more info about Duby Drive`}
                                    data-tooltip-id="vender_whatsapp"
                                    data-tooltip-content={"Email"}
                                    className="card-btn background-orange send-btn"
                                >
                                    <Image
                                    src={sendIcon}
                                    alt="send-icon"
                                    className="card-btn-icons"
                                    width={400}
                                    height={200}
                                    />
                                </a>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
                {/* <HomePageBannerOne /> */}

                <div className="section-four-header mt-5">
                    <h3 className='primary_text_color'>Find all feature and verified car rental services near you</h3>
                    <button onClick={navigateToCars} className="btn primary_background_color text-white">
                        View all <Image src={nextIcon} className='ms-1' alt="next-icon" height={15} width={15} />
                    </button>
                </div>
                <p className="para">
                    Compare price with all other car rental businesses near you to find the best rental deals available.
                </p>
                <div className="car-cards-container mt-0">
                    <Slider {...settings}>
                        {secondSliderCars.map((item) => (
                            <div key={item.id} className="car-cards car-cards-slider">
                            <Link href={{ pathname: "/car-details", query: { id: item.id } }}>
                                <div className="card-header">
                                    {item.carImages && item.carImages.length > 0 ? (
                                    <Image
                                        src={item.featureImage}
                                        alt={item.carImages[0]}
                                        onError={(e) => {
                                        e.target.src = defaultCarImage.src;
                                        }}
                                        className="card-header-img"
                                        width={400}
                                        height={200}
                                    />
                                    ) : (
                                    <Image
                                        src={defaultCarImage.src}
                                        alt="default-car"
                                        className="card-header-img"
                                        width={400}
                                        height={200}
                                    />
                                    )}
                                    {carsData !== null ? (
                                    <div className="car-card-badge-container">
                                        {item.dealer.package.premium == true ? (
                                        <span className="badge text-bg-dark car-card-badge">
                                            Premium
                                        </span>
                                        ) : (
                                        ""
                                        )}
                                        {item.dealer.package.featured == true ? (
                                        <span className="badge text-bg-warning car-card-badge">
                                            Featured
                                        </span>
                                        ) : (
                                        ""
                                        )}
                                        {item.dealer.package.verify == true ? (
                                        <span className="badge text-bg-success car-card-badge">
                                            Verified
                                        </span>
                                        ) : (
                                        ""
                                        )}
                                        {item.discountOfferPercentage == "0" ? (
                                        ""
                                        ) : (
                                        <span className="badge text-bg-light car-card-badge">
                                            {item.discountOfferPercentage}% Discount
                                        </span>
                                        )}
                                    </div>
                                    ) : (
                                    ""
                                    )}
                                </div>
                                <div className="card-body p-3">
                                    <h4>{item.modelNo}</h4>
                                    <div className="card-details">
                                    <div className="details-one">
                                        {item.discountOfferPercentage == "0" ? (
                                        <span className="details text-orange">
                                            <span className="text-line">
                                            {" "}
                                            <br />{" "}
                                            </span>
                                        </span>
                                        ) : (
                                        <span className="details text-orange">
                                            <span className="text-line">
                                            {item.dayBasisCost}
                                            </span>
                                        </span>
                                        )}
                                        <span className="details text-orange">
                                        AED{" "}
                                        {Number(item.dayBasisCost) -
                                            (Number(item.dayBasisCost) / 100) *
                                            Number(item.discountOfferPercentage)}{" "}
                                        <span className="primary_text_color">/ day</span>
                                        </span>
                                        <div className="d-flex align-items-center">
                                        <Image
                                            src={roadIcon}
                                            alt="road-icon"
                                            className="spec-icon"
                                            width={400}
                                            height={200}
                                        />
                                        <span className="details text-gray">
                                            {item.dayBasisMileageLimit}
                                        </span>
                                        </div>
                                    </div>
                                    <div className="details-two">
                                        {item.discountOfferPercentage == "0" ? (
                                        <span className="details text-orange">
                                            <span className="text-line">
                                            {" "}
                                            <br />{" "}
                                            </span>
                                        </span>
                                        ) : (
                                        <span className="details text-orange">
                                            <span className="text-line">{item.weeklyCost}</span>
                                        </span>
                                        )}
                                        <span className="details text-orange">
                                        AED{" "}
                                        {Number(item.weeklyCost) -
                                            (Number(item.weeklyCost) / 100) *
                                            Number(item.discountOfferPercentage)}{" "}
                                        <span className="primary_text_color">/ week</span>
                                        </span>
                                        <div className="d-flex align-items-center">
                                        <Image
                                            src={roadIcon}
                                            alt="road-icon"
                                            className="spec-icon"
                                            width={400}
                                            height={200}
                                        />
                                        <span className="details text-gray">
                                            {item.weeklyMileageLimit}
                                        </span>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="specs">
                                    <div className="medium-spec w-auto px-3">
                                        <Image
                                        src={typeIcon}
                                        alt="type-icon"
                                        className="spec-icon"
                                        width={400}
                                        height={200}
                                        />
                                        <span className="text-gray">{item.type.name}</span>
                                    </div>
                                    <div className="small-spec">
                                        <Image
                                        src={doorIcon}
                                        alt="door-icon"
                                        className="spec-icon"
                                        width={400}
                                        height={200}
                                        />
                                        <span className="text-gray">{item.doors}</span>
                                    </div>
                                    <div className="small-spec">
                                        <Image
                                        src={seatIcon}
                                        alt="seat-icon"
                                        className="spec-icon"
                                        width={400}
                                        height={200}
                                        />
                                        <span className="text-gray">{item.seats}</span>
                                    </div>
                                    <div className="medium-spec">
                                        <Image
                                        src={fuelIcon}
                                        alt="fuel-icon"
                                        className="spec-icon"
                                        width={400}
                                        height={200}
                                        />
                                        <span className="text-gray">{item.fuelType.name}</span>
                                    </div>
                                    <div className="medium-spec">
                                        <Image
                                        src={transmissionIcon}
                                        alt="transmission-icon"
                                        className="spec-icon"
                                        width={400}
                                        height={200}
                                        />
                                        <span className="text-gray">
                                        {item.autoTransmission == "Yes" ? "Auto" : "Manual"}
                                        </span>
                                    </div>
                                    </div>
                                </div>
                                </Link>
                                <div className=" d-flex justify-content-between p-2">
                                <div className="card-footer-dealer-logo-container ">
                                    <Image
                                    src={`${item.dealer.logo}`}
                                    alt="dealer-logo"
                                    className="dealer-logo"
                                    width={100}
                                    height={200}
                                    style={{ objectFit: "contain" }}
                                    />
                                </div>
                                <div className="card-footer-details">
                                    <div className="footer-info">
                                    <Image
                                        src={checkIcon}
                                        alt="check-icon"
                                        className="footer-icons check-icon"
                                        width={400}
                                        height={200}
                                    />
                                    <span className="card-footer-text text-gray">
                                        {item.rentalDays} day rental available
                                    </span>
                                    </div>
                                    <div className="footer-info">
                                    <Image
                                        src={infoIcon}
                                        alt="inof-icon"
                                        className="footer-icons"
                                        width={400}
                                        height={200}
                                    />
                                    <span className="card-footer-text text-gray">
                                        Deposit: {item.securityDeposit}
                                    </span>
                                    </div>
                                    <div className="footer-info">
                                    <Image
                                        src={checkIcon}
                                        alt="check-icon"
                                        className="footer-icons check-icon"
                                        width={400}
                                        height={200}
                                    />
                                    <span className="card-footer-text text-gray">
                                        Insurance Included
                                    </span>
                                    </div>
                                </div>
                                </div>
                                <div className="card-buttons">
                                <a
                                    href={`tel:${item.dealer.contactNumber}`}
                                    data-tooltip-id="vender_contact"
                                    data-tooltip-content={"Phone"}
                                    className="card-btn primary_background_color phone-btn"
                                >
                                    <Image
                                    src={callIcon}
                                    alt="call-icon"
                                    className="card-btn-icons"
                                    width={400}
                                    height={200}
                                    />
                                </a>
                                <a
                                    data-tooltip-id="vender_email"
                                    data-tooltip-content={"Whatsapp"}
                                    className="card-btn background-green"
                                    onClick={() => {
                                    const phone = item.dealer.whatsappNumber;
                                    const message = `Hi, I've found your (${
                                        item.modelNo
                                    }) at dubydrive.com and I'd like to rent the listed car. ${window.location.toString()} Let me know if it's still available. Thanks!`;

                                    const url = `https://api.whatsapp.com/send?phone=${phone}&text=${encodeURIComponent(
                                        message
                                    )}&type=phone_number&app_absent=0`;

                                    window.open(url, "_blank");
                                    }}
                                >
                                    <Image
                                    src={whatsappIcon}
                                    alt="whatsapp-icon"
                                    className="card-btn-icons"
                                    width={400}
                                    height={200}
                                    />
                                </a>
                                <a
                                    href={`mailto:${item.dealer.user.email}?body=Hello can i get more info about Duby Drive`}
                                    data-tooltip-id="vender_whatsapp"
                                    data-tooltip-content={"Email"}
                                    className="card-btn background-orange send-btn"
                                >
                                    <Image
                                    src={sendIcon}
                                    alt="send-icon"
                                    className="card-btn-icons"
                                    width={400}
                                    height={200}
                                    />
                                </a>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
                <HomePageBannerTwo />

                <div className="section-four-header mt-5">
                    <h3 className='primary_text_color'>Find all verified car rental services near you</h3>
                    <button onClick={navigateToCars} className="btn primary_background_color text-white">
                        View all <Image src={nextIcon} className='ms-1' alt="next-icon" height={15} width={15} />
                    </button>
                </div>
                <p className="para mb-0">
                    Compare price with all other car rental businesses near you to find the best rental deals available.
                </p>
                <div className="car-cards-container mt-0">
                    <Slider {...settings}>
                        {thirdSliderCars.map((item) => (
                            <div key={item.id} className="car-cards car-cards-slider">
                            <Link href={{ pathname: "/car-details", query: { id: item.id } }}>
                                <div className="card-header">
                                    {item.carImages && item.carImages.length > 0 ? (
                                    <Image
                                        src={item.featureImage}
                                        alt={item.carImages[0]}
                                        onError={(e) => {
                                        e.target.src = defaultCarImage.src;
                                        }}
                                        className="card-header-img"
                                        width={400}
                                        height={200}
                                    />
                                    ) : (
                                    <Image
                                        src={defaultCarImage.src}
                                        alt="default-car"
                                        className="card-header-img"
                                        width={400}
                                        height={200}
                                    />
                                    )}
                                    {carsData !== null ? (
                                    <div className="car-card-badge-container">
                                        {item.dealer.package.premium == true ? (
                                        <span className="badge text-bg-dark car-card-badge">
                                            Premium
                                        </span>
                                        ) : (
                                        ""
                                        )}
                                        {item.dealer.package.featured == true ? (
                                        <span className="badge text-bg-warning car-card-badge">
                                            Featured
                                        </span>
                                        ) : (
                                        ""
                                        )}
                                        {item.dealer.package.verify == true ? (
                                        <span className="badge text-bg-success car-card-badge">
                                            Verified
                                        </span>
                                        ) : (
                                        ""
                                        )}
                                        {item.discountOfferPercentage == "0" ? (
                                        ""
                                        ) : (
                                        <span className="badge text-bg-light car-card-badge">
                                            {item.discountOfferPercentage}% Discount
                                        </span>
                                        )}
                                    </div>
                                    ) : (
                                    ""
                                    )}
                                </div>
                                <div className="card-body p-3">
                                    <h4>{item.modelNo}</h4>
                                    <div className="card-details">
                                    <div className="details-one">
                                        {item.discountOfferPercentage == "0" ? (
                                        <span className="details text-orange">
                                            <span className="text-line">
                                            {" "}
                                            <br />{" "}
                                            </span>
                                        </span>
                                        ) : (
                                        <span className="details text-orange">
                                            <span className="text-line">
                                            {item.dayBasisCost}
                                            </span>
                                        </span>
                                        )}
                                        <span className="details text-orange">
                                        AED{" "}
                                        {Number(item.dayBasisCost) -
                                            (Number(item.dayBasisCost) / 100) *
                                            Number(item.discountOfferPercentage)}{" "}
                                        <span className="primary_text_color">/ day</span>
                                        </span>
                                        <div className="d-flex align-items-center">
                                        <Image
                                            src={roadIcon}
                                            alt="road-icon"
                                            className="spec-icon"
                                            width={400}
                                            height={200}
                                        />
                                        <span className="details text-gray">
                                            {item.dayBasisMileageLimit}
                                        </span>
                                        </div>
                                    </div>
                                    <div className="details-two">
                                        {item.discountOfferPercentage == "0" ? (
                                        <span className="details text-orange">
                                            <span className="text-line">
                                            {" "}
                                            <br />{" "}
                                            </span>
                                        </span>
                                        ) : (
                                        <span className="details text-orange">
                                            <span className="text-line">{item.weeklyCost}</span>
                                        </span>
                                        )}
                                        <span className="details text-orange">
                                        AED{" "}
                                        {Number(item.weeklyCost) -
                                            (Number(item.weeklyCost) / 100) *
                                            Number(item.discountOfferPercentage)}{" "}
                                        <span className="primary_text_color">/ week</span>
                                        </span>
                                        <div className="d-flex align-items-center">
                                        <Image
                                            src={roadIcon}
                                            alt="road-icon"
                                            className="spec-icon"
                                            width={400}
                                            height={200}
                                        />
                                        <span className="details text-gray">
                                            {item.weeklyMileageLimit}
                                        </span>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="specs">
                                    <div className="medium-spec w-auto px-3">
                                        <Image
                                        src={typeIcon}
                                        alt="type-icon"
                                        className="spec-icon"
                                        width={400}
                                        height={200}
                                        />
                                        <span className="text-gray">{item.type.name}</span>
                                    </div>
                                    <div className="small-spec">
                                        <Image
                                        src={doorIcon}
                                        alt="door-icon"
                                        className="spec-icon"
                                        width={400}
                                        height={200}
                                        />
                                        <span className="text-gray">{item.doors}</span>
                                    </div>
                                    <div className="small-spec">
                                        <Image
                                        src={seatIcon}
                                        alt="seat-icon"
                                        className="spec-icon"
                                        width={400}
                                        height={200}
                                        />
                                        <span className="text-gray">{item.seats}</span>
                                    </div>
                                    <div className="medium-spec">
                                        <Image
                                        src={fuelIcon}
                                        alt="fuel-icon"
                                        className="spec-icon"
                                        width={400}
                                        height={200}
                                        />
                                        <span className="text-gray">{item.fuelType.name}</span>
                                    </div>
                                    <div className="medium-spec">
                                        <Image
                                        src={transmissionIcon}
                                        alt="transmission-icon"
                                        className="spec-icon"
                                        width={400}
                                        height={200}
                                        />
                                        <span className="text-gray">
                                        {item.autoTransmission == "Yes" ? "Auto" : "Manual"}
                                        </span>
                                    </div>
                                    </div>
                                </div>
                                </Link>
                                <div className=" d-flex justify-content-between p-2">
                                <div className="card-footer-dealer-logo-container ">
                                    <Image
                                    src={`${item.dealer.logo}`}
                                    alt="dealer-logo"
                                    className="dealer-logo"
                                    width={100}
                                    height={200}
                                    style={{ objectFit: "contain" }}
                                    />
                                </div>
                                <div className="card-footer-details">
                                    <div className="footer-info">
                                    <Image
                                        src={checkIcon}
                                        alt="check-icon"
                                        className="footer-icons check-icon"
                                        width={400}
                                        height={200}
                                    />
                                    <span className="card-footer-text text-gray">
                                        {item.rentalDays} day rental available
                                    </span>
                                    </div>
                                    <div className="footer-info">
                                    <Image
                                        src={infoIcon}
                                        alt="inof-icon"
                                        className="footer-icons"
                                        width={400}
                                        height={200}
                                    />
                                    <span className="card-footer-text text-gray">
                                        Deposit: {item.securityDeposit}
                                    </span>
                                    </div>
                                    <div className="footer-info">
                                    <Image
                                        src={checkIcon}
                                        alt="check-icon"
                                        className="footer-icons check-icon"
                                        width={400}
                                        height={200}
                                    />
                                    <span className="card-footer-text text-gray">
                                        Insurance Included
                                    </span>
                                    </div>
                                </div>
                                </div>
                                <div className="card-buttons">
                                <a
                                    href={`tel:${item.dealer.contactNumber}`}
                                    data-tooltip-id="vender_contact"
                                    data-tooltip-content={"Phone"}
                                    className="card-btn primary_background_color phone-btn"
                                >
                                    <Image
                                    src={callIcon}
                                    alt="call-icon"
                                    className="card-btn-icons"
                                    width={400}
                                    height={200}
                                    />
                                </a>
                                <a
                                    data-tooltip-id="vender_email"
                                    data-tooltip-content={"Whatsapp"}
                                    className="card-btn background-green"
                                    onClick={() => {
                                    const phone = item.dealer.whatsappNumber;
                                    const message = `Hi, I've found your (${
                                        item.modelNo
                                    }) at dubydrive.com and I'd like to rent the listed car. ${window.location.toString()} Let me know if it's still available. Thanks!`;

                                    const url = `https://api.whatsapp.com/send?phone=${phone}&text=${encodeURIComponent(
                                        message
                                    )}&type=phone_number&app_absent=0`;

                                    window.open(url, "_blank");
                                    }}
                                >
                                    <Image
                                    src={whatsappIcon}
                                    alt="whatsapp-icon"
                                    className="card-btn-icons"
                                    width={400}
                                    height={200}
                                    />
                                </a>
                                <a
                                    href={`mailto:${item.dealer.user.email}?body=Hello can i get more info about Duby Drive`}
                                    data-tooltip-id="vender_whatsapp"
                                    data-tooltip-content={"Email"}
                                    className="card-btn background-orange send-btn"
                                >
                                    <Image
                                    src={sendIcon}
                                    alt="send-icon"
                                    className="card-btn-icons"
                                    width={400}
                                    height={200}
                                    />
                                </a>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
                <HomePageBannerThree />
            </div>
        </>
    );
};

export default SectionFour;
