import React, { useEffect, useState } from "react";
import axios from "axios";

const HomePageBannerTwo = () => {

    const [bannerData, setBannerData] = useState([]);
    const bannerNumber = 2;

    const fetchingBannerApi = async () => {
        const pageName = "Home Page";
        try {
            const response = await axios({
                method: "GET",
                url: `${process.env.NEXT_PUBLIC_REACT_APP_HOST_URL}/api/banner`,
                data: { pageName }
            });

            if (response.status === 200) {
                setBannerData(response.data);
            }
        } catch (error) {
            console.log('Failed while fetching banner API by page name: ', error);
        }
    };

    useEffect(() => {
        fetchingBannerApi();
    }, []);

    return (
        <>
            <div className='banner-container'>
                {bannerData.length > 0 ?
                    bannerData
                        .filter(item => item.bannerNumber === bannerNumber && item.active)
                        .map((item, index) => (
                            <div style={{ width: '85%' }} key={index} dangerouslySetInnerHTML={{ __html: item.html }}></div>
                        ))
                    :
                    <p>No banners available.</p>
                }
            </div>
        </>
    );
};

export default HomePageBannerTwo;
