'use client'; // This directive ensures the component is client-side only

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/navigation'; // Use useRouter from next/router
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Image from 'next/image';


const SectionOne = () => {
    const router = useRouter(); // Initialize useRouter hook
    const [carData, setCarData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);

    const fetchingCarData = async () => {
        try {
            console.log("Fetching car data...");
            const response = await axios.get(`${process.env.NEXT_PUBLIC_REACT_APP_HOST_URL}/api/cars`);
            console.log("Response:", response);
            if (response.status === 200) {
                setCarData(response.data);
                console.log("Car Data:", response.data);
            }
        } catch (error) {
            console.error('Failed while fetching car data API:', error);
        }
    };
    const searchInputOnChangeHandler = (e) => {
        const searchValue = e.target.value.toLowerCase();
        if(searchValue===""){
            setFilterData([]);
            setIsDropdownVisible(false);
        }
        const filteredData = carData.result?.filter((car) =>
            car.modelNo.toLowerCase().startsWith(searchValue)
        );
        setFilterData(filteredData);
        setIsDropdownVisible(true);
    };
    
    

    const onKeyPressHandler = (e) => {
        if (e.key === 'Enter') {
            if (filterData.length > 0) {
                const carsIdArray = filterData.map(item => item.id);
                router.push({
                    pathname: '/cars',
                    query: { cars_id: carsIdArray.join(',') }
                });
            } else {
                toast.error('No result found');
            }
        }
    };

    const handleClickOutside = (event) => {
        const searchContainer = document.querySelector('.search-input');
        
        // Check if the clicked target is outside the search container
        if (searchContainer && !searchContainer.contains(event.target)) {
            setIsDropdownVisible(false);  // Hide the dropdown
        }
    };

    useEffect(() => {
        fetchingCarData();
        const searchInput = document.getElementById('home_search_input');
        if (searchInput) searchInput.value = '';

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <ToastContainer theme='dark' />
            <div className='home-section-one'>
                <div className='sub-section col-md-6 text-white'>
                    <h1 className='text-center text-wrap home-main-text home-main-heading'>CLICK, SELECT AND DRIVE</h1>
                    <p className='text-center text-wrap home-main-text'>Book directly from local car rental. No commission, no mark-ups.</p>

                    <div className="search-container">
                        <div className="search-input">
                            <div className="search-icon"></div>
                            <input 
                                id='home_search_input' 
                                autoComplete='off' 
                                onChange={searchInputOnChangeHandler} 
                                onKeyPress={onKeyPressHandler} 
                                type="text" 
                                placeholder='Search Car Rentals in Dubai' 
                            />
                            {isDropdownVisible && filterData?.length > 0 && (
                                <div className="search-results-container col-md-12">
                                    {filterData.map((item, index) => (
                                        <div
                                            key={index}
                                            onClick={() => router.push(`/car-details?id=${item.id}`)}
                                            className='search-result-item'
                                        >
                                            <p className='p-2 mb-1'>{item.modelNo}</p>
                                            <Image
                                                src={`${item.carImages[0]}`}
                                                alt={item.carImages[0]}
                                                height={50}
                                                width={100}
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <button 
                            onClick={() => router.push('/cars')} 
                            className="btn btn-primary home_btn primary_background_color links_hover view-all-cars-btn d-flex"
                        >
                            View All Cars <div className="btn-icon"></div>
                        </button>
                       
                    </div>
                </div>
            </div>
        </>
    );
};

export default SectionOne;
