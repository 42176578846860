'use client';
import React from 'react';
import { useRouter } from 'next/navigation';
import Image from 'next/image';

const SectionThirteen = () => {

    const router = useRouter();

    const handleNavigation = () => {
        router.push('/add-your-vehicle');
    };

    return (
        <>
            <div className='home-section-thirteen text-white'>
                <div className='section-thirteen-content'>
                    <h2 className='text-center'>ARE YOU A CAR RENTAL COMPANY? JOIN US.</h2>
                    <p onClick={handleNavigation} style={{cursor:'pointer', fontSize:'22px', marginTop:'1rem'}} className='text-center redirect-page'>List your cars with the UAE's biggest car rental & leasing marketplace today!</p>
                </div>
            </div>
        </>
    );
};

export default SectionThirteen;